import {cloneDeep, difference, flatMap, isNil} from 'lodash'

import {GENDER} from '../../../utils/constants'

const reactSelectStyles = {
  container: styles => ({
    ...styles,
    width: '100%',
    borderRadius: 4,
    backgroundColor: '#fff',
    appearance: 'none',
    color: 'var(--input-text-color)',
    fontSize: 14,
    letterSpacing: -0.4,
    fontFamily: 'Muli-SemiBold',
  }),
  valueContainer: styles => ({...styles, padding: '0 0 0 15px'}),
  control: styles => ({...styles, border: '1px solid #e2e9ef', height: 'auto', marginBottom: 20}),
  indicatorSeparator: () => ({display: 'none'}),
  input: (styles) => ({...styles, padding: 0, margin: '0px'}),
  singleValue: (styles) => ({...styles, color: 'var(--input-text-color)'}),
  menuList: (styles) => ({...styles, maxHeight: 200}),
  clearIndicator: (styles) => ({...styles, display: 'none'}),
  placeholder: (styles) => ({...styles, color: 'var(--placeholder-color)',}),
}

const createSelectOption = (options, valueProp = null, labelProp = null) => {
  if (options && options.length) {
    if (typeof options === 'string') {
      return {
        label: options,
        value: options
      }
    }
    return (options?.constructor.name === 'Object' ?
      Object.keys(options)
      : options).map(data => {
      return {
        label: labelProp ? data[labelProp] : data,
        value: valueProp ? data[valueProp] : data
      }
    })
  }

  return []
}

const isValidObj = (obj) => {
  if (obj) {
    if (obj.constructor.name === 'Object') {
      return Object.keys(obj).length
    } else {
      return obj.length
    }
  } else {
    return false
  }
}

/**
 * Merge one object into to given object
 * @param {object} stateToMerge Object to merge
 * @param {object} currentState Current object
 * @param {number} depth Depth of object to merge
 * @returns {object} Merged object
 */
const mergeReduxValues = (stateToMerge, currentState, depth) => {
  if (stateToMerge !== undefined) {
    // eslint-disable-next-line
    return Object.keys(currentState).reduce((accDepth1, keyDepth1) => {
      if (stateToMerge[keyDepth1]) {
        if (depth === 1) {
          return {...accDepth1, [keyDepth1]: stateToMerge[keyDepth1]}
        } else if (depth === 2) {
          let merged = Object.keys(currentState[keyDepth1]).reduce((accDepth2, keyDepth2) => {
            if (stateToMerge[keyDepth1][keyDepth2]) {
              return {...accDepth2, [keyDepth2]: stateToMerge[keyDepth1][keyDepth2]}
            } else {
              return {...accDepth2, [keyDepth2]: currentState[keyDepth1][keyDepth2]}
            }
          }, {})

          return {...accDepth1, [keyDepth1]: merged}
        }
      } else {
        return {...accDepth1, [keyDepth1]: currentState[keyDepth1]}
      }
    }, {})
  } else {
    return currentState
  }
}

const registerParticipantsInitialValues = (filter, type, reduxState, runsCount) => {
  let initialValue = {}
  if (type === 'demographic') {
    if (filter.filter_type === 'numeric') {
      initialValue = {
        from: filter.min_value,
        to: filter.max_value,
        ...filter
      }
    } else {
      initialValue = {
        filterOptions: filter.filter_options.reduce((acc, val) => [...acc, {
          label: val.label,
          checked: true
        }], []),
        ...filter
      }
    }
  } else if (type === 'custom_attributes') {
    if (filter.filter_type === 'numeric') {
      initialValue = {
        mode: 'Include only',
        comparator: 'equal to',
        value: 1,
        from: '',
        to: '',
        ...filter
      }
    } else {
      initialValue = {
        mode: 'Include only',
        comparator: 'equal to',
        value: '',
        oneOfValue: [],
        ...filter
      }
    }
  } else if (type === 'cintProfiling') {
    initialValue = {
      ...filter,
      variables: filter.variables.reduce((ary, val) => {
        return [...ary, {label: val.name, checked: false, id: val.id}]
      }, [])
    }
  }

  if (type === 'quality') {
    return mergeReduxValues(reduxState, {
      ...reduxState, quality: {
        is_one_worker_per_ip: true,
        is_isp_check_enabled: true,
        is_hq_participants_only: true,
        is_attention_check_enabled: true,
        is_country_check_enabled: true,
        is_very_hq_enabled: false
      }
    }, 1)
  } else if (type === 'previousParticipation') {
    return mergeReduxValues(reduxState, {
      ...reduxState, previousParticipation: {
        prescreen_type: !!runsCount ? '' : 'does_not_matter',
        custom: {
          mode: 'include',
          status_level: 'started_any',
          ps_level: !!runsCount ? 'project' : 'user',
          ps_ids: [],
        }
      }
    }, 1)
  } else if (type === 'cintProfiling') {
    return mergeReduxValues(reduxState, {
      ...reduxState, [type]: {
        ...reduxState[type],
        [filter.id]: initialValue
      }
    }, 2)
  } else {
    return mergeReduxValues(reduxState, {
      ...reduxState, [type]: {
        ...reduxState[type],
        [type === 'cintProfiling' ? filter.id : filter.name]: initialValue
      }
    }, 2)
  }
}

const unregisterParticipantsInitialValues = (state, type, filterToRemove) => {
  let formikValues = state
  if (type === 'quality' || type === 'previousParticipation') { // Direct property remove
    return Object.keys(formikValues).reduce((acc, key) => {
      return key !== filterToRemove ? ({...acc, [key]: formikValues[key]}) : acc
    }, {})
  } else {
    let newState = Object.keys(formikValues[type]).reduce((acc, key) => { // Inner property remove
      return key !== filterToRemove ? ({...acc, [key]: formikValues[type][key]}) : acc
    }, {})
    return {...state, [type]: newState}
  }
}

const formatDemographicToCustomFilter = (filters) => {
  let formattedFilters = []

  Object.values(filters).forEach((filter) => {
    if (filter.filter_type === 'numeric') {
      formattedFilters.push({
        mode: 'Include only',
        name: filter.name,
        min_value: filter.from,
        max_value: filter.to,
        comparator: 'between',
        is_demographic: true,
        filter_type: filter.filter_type
      })
    } else if (['select_box', 'check_box'].includes(filter.answer_type)) {
      if (isAnyValueChecked(filter)) {
        formattedFilters.push({
          mode: 'Include only',
          name: filter.name,
          values: getCheckedValues(filter),
          comparator: 'in',
          is_demographic: true,
          answer_type: filter.answer_type,
          filter_options: getCheckedValues(filter)
        })
      }
    }
  })

  return formattedFilters
}

const formatCustomAttributesFilter = (filters) => {
  let formattedFilters = []

  filters.forEach((filter) => {
    console.log(filter)
    // if (filter.filter_type === 'numeric') {
    //   formattedFilters.push({
    //     name: filter.name,
    //     filter_type: filter.filter_type,
    //     mode: filter.mode,
    //     comparator: filter.comparator,
    //     value: ['blank', 'not blank', 'between'].includes(filter.comparator) ? '' : filter.value,
    //     values: [],
    //     min_value: filter.comparator === 'between' ? filter.from : '',
    //     max_value: filter.comparator === 'between' ? filter.to : '',
    //   })
    // } else if (filter.filter_type === 'text') {
    //   formattedFilters.push({
    //     name: filter.name,
    //     filter_type: filter.filter_type,
    //     mode: filter.mode,
    //     comparator: filter.comparator,
    //     value: ['blank', 'not blank', 'in'].includes(filter.comparator) ? '' : filter.value,
    //     values: filter.comparator === 'in' ? filter.oneOfValue : [],
    //     min_value: '',
    //     max_value: '',
    //   })
    // }
  })

  return formattedFilters
}

const isAnyValueChecked = (filter) => {
  return filter.filterOptions.some(value => value.checked)
}

const getCheckedValues = (filter) => {
  return filter.filterOptions
    .filter(value => value.checked)
    .map(value => value.label)
}


const getPreviousParticipationText = data => {
  let displayText = ''
  if (data.ps_type === 'project_include') {
    displayText = 'Only allow participants who have completed other runs in this project'
  } else if (data.ps_type === 'project_exclude') {
    displayText = 'Exclude all participants who have started other runs in this project'
  } else if (data.ps_type === 'custom') {
    let modeText = data.ps_mode === 'include' ? 'Only allow ' : 'Exclude '
    let levelText
    switch (data.ps_level) {
      case 'project':
        levelText = 'the other runs in this project'
        break
      case 'runs':
        levelText = 'these specific runs'
        break
      case 'projects':
        levelText = 'the other runs in these projects'
        break
      case 'user':
        levelText = 'the other runs in my account'
        break
      default:
        levelText = ''
    }

    displayText = `${modeText} participants who ${data.ps_status} ${data.ps_comparator} of ${levelText}`
  }

  return displayText
}

const getFormattedPrescreenID = data => {
  let split = data.split('ID: ')
  return split[1].replace(/\)/g, '')
}

/* Parse selected filter to be displayed in overview panel */
const parseFilterValue = (filter) => {
  let val = ''

  if (filter.comparator.includes('blank')) {
    val = ''
  } else if (filter.comparator === 'between') {
    val = `${filter.from} and ${filter.to}`
  } else if (filter.comparator === 'in') {
    if (filter.values && filter.values.length)
      val = filter.values.join(', ')
    else
      val = ''
  } else {
    val = filter.value
  }

  return val
}

const isGuidedTrackLink = (taskUrl) => {
  return taskUrl && taskUrl.includes('guidedtrack.com')
}

const getHelpText = (taskUrl) => {
  let result = {}
  if (taskUrl) {
    if (taskUrl.includes('guidedtrack.com')) {
      result = {
        instruction: 'automatically integrates',
        url: 'https://www.positly.com/support/instructions-for-integrating-guidedtrack-with-positly/',
        platform: 'GuidedTrack',
      }
    } else if (taskUrl.includes('docs.google.com/forms') || taskUrl.includes('forms.gle')) {
      result = {
        instruction: 'Google Form integration instructions',
        url: 'https://www.positly.com/support/instructions-for-integrating-google-forms-with-positly/',
        platform: 'Google Forms',
      }
    } else if (taskUrl.includes('qualtrics.com')) {
      result = {
        instruction: 'Qualtrics integration instructions',
        url: 'https://www.positly.com/support/instructions-for-integrating-qualtrics-with-positly/',
        platform: 'Qualtrics',
      }
    } else if (taskUrl.includes('surveymonkey.com')) {
      result = {
        instruction: 'Survey Monkey integration instructions',
        url: 'https://www.positly.com/support/instructions-for-integrating-surveymonkey-with-positly/',
        platform: 'Survey Monkey',
      }
    } else if (taskUrl.includes('typeform.com')) {
      result = {
        instruction: 'Typeform integration instructions',
        url: 'https://www.positly.com/support/instructions-for-integrating-typeform-with-positly/',
        platform: 'Typeform',
      }
    } else if (taskUrl.includes('getfeedback.com')) {
      result = {
        instruction: 'GetFeedback integration instructions',
        url: 'https://www.positly.com/support/instructions-for-integrating-getfeedback-with-positly/',
        platform: 'GetFeedback',
      }
    } else if (taskUrl.includes('gorilla.sc') || taskUrl.includes('research.sc')) {
      result = {
        instruction: 'Gorilla integration instructions',
        url: 'https://www.positly.com/support/instructions-for-integrating-gorilla-with-positly/',
        platform: 'Gorilla',
      }
    } else if (taskUrl.includes('form.jotform.com')) {
      result = {
        instruction: 'JotForm integration instructions',
        url: 'https://www.positly.com/support/instructions-for-integrating-jotform-with-positly/',
        platform: 'JotForm',
      }
    } else if (taskUrl.includes('pointerpro.com')) {
      result = {
        instruction: 'Pointerpro integration instructions',
        url: 'https://www.positly.com/support/instructions-for-integrating-pointerpro-with-positly/',
        platform: 'Pointerpro',
      }
    } else if (taskUrl.includes('forms.office.com')) {
      result = {
        instruction: 'Microsoft Forms integration instructions',
        url: 'https://www.positly.com/support/instructions-for-integrating-microsoft-forms-with-positly/',
        platform: 'Microsoft Forms',
      }
    } else if (taskUrl.includes('pollunit.com')) {
      result = {
        instruction: 'PollUnit integration instructions',
        url: 'https://www.positly.com/support/instructions-for-integrating-pollunit-with-positly/',
        platform: 'PollUnit',
      }
    }
    else {
      result = {
        instruction: 'Query string integration instructions',
        url: 'https://www.positly.com/support/integrate-your-activity-to-collect-ids-and-basic-demographic-information-using-url-query-string-parameters/',
        platform: 'Others',
      }
    }
  }

  return result
}

const formatCintProfilingFilter = filters => {
  let formattedFilters = []
  if (filters && isValidObj(filters)) {
    Object.values(filters).forEach((filter) => {
      formattedFilters.push({
        filter_id: filter.id,
        variable_ids: filter.variables.filter(variable => variable.checked).map(value => value.id)
      })
    })
  }

  return formattedFilters
}

const getActivityLanguageById = (languages, id) => (
  languages && id
    ? languages.find(language => language.id === id)
    : {}
)

const getActivityLanguageByName = (languages, name) => (
  languages && name
    ? languages.find(language => language.name === name)
    : {}
)

const commonQSAttributes = {
  participant_id: 'participantID',
  assignment_id: 'assignmentID',
  computer_id: 'computerID',
  computer_country: 'computerCountry',
  device: 'device',
  started_at: 'startedAt',
  project_id: 'projectID'
}

const formatAsCurrency = (amount, fractionDigits = 2, symbol = '$') => {
  if (!amount) return ''

  amount = parseFloat(amount).toFixed(fractionDigits)
  return symbol + amount
}

const screeninLink = (taskId) => `${window.location.protocol}//${window.location.host}/#/start?task_id=${taskId}`
const screenoutLink = `${window.location.protocol}//${window.location.host}/#/end`

const removeHighlight = (id, isCheckBox = false) => {
  const element = document.getElementById(id)

  if (!element) return

  element.classList.remove('highlight-box')
  if (isCheckBox) {
    element.classList.remove('pl-2')
  }
}

const highlightControl = (id, isCheckBox = false) => {
  const element = document.getElementById(id)

  if (!element) return

  removeHighlight(id, isCheckBox)
  const container = element.closest('.box')

  if (container)
    container.scrollIntoView({behavior: 'smooth'})
  else
    element.scrollIntoView({behavior: 'smooth'})

  element.classList.add('highlight-box')
  if (isCheckBox) {
    element.classList.add('pl-2')
  }
}

const urlRegex = /^(http|https)?(:\/\/)?(www\.)?(([a-z0-9-]+\.)+)([a-z0-9-]+)(.*)?$/

const getFormattedActivityUrl = (activityUrl, taskId) => {
  let updatedActivityUrl = activityUrl
  const urlParams = `projectID=${taskId}`

  // Add Protocol
  if (!/^https?:\/\//.test(updatedActivityUrl))
    updatedActivityUrl = 'http://' + updatedActivityUrl

  // Add Query String Params
  if (/^https?:\/\/(.*)\?(.*)/.test(updatedActivityUrl))
    updatedActivityUrl = updatedActivityUrl + '&' + urlParams
  else
    updatedActivityUrl = updatedActivityUrl + '?' + urlParams

  return updatedActivityUrl
}

const ageFilterForCint = {
  id: 'cintAge',
  name: 'Target by age',
  categoryName: 'Age',
  min_value: 18,
  max_value: 99,
  filter_label: 'Age',
  question_text: 'Target participants from the following age group',
  from: '18',
  to: '78'
}

const genderFilterForCint = {
  id: 'cintGender',
  name: 'Target by gender',
  categoryName: 'Gender',
  filter_label: 'Gender',
  question_text: 'Target participants by gender',
  filter_options: [
    {
      title: 'male',
      label: 'Male',
      is_checked: false
    },
    {
      title: 'female',
      label: 'Female',
      is_checked: false
    }
  ]
}

const censusQuota = {
  id: 'censusQuota',
  name: 'Target by age and gender',
  min_value: 18,
  max_value: 99,
  from: '18',
  to: '78',
  gender: [{id: 'male', name: 'Male'}, {id: 'female', name: 'Female'}]
}

const genderQuota = {
  id: 'cintGender',
  name: 'Target by gender',
  text: 'Target participants by gender',
  variables: [{id: 'male', name: 'Male'}, {id: 'female', name: 'Female'}]
}

const skipOverlappingCintVariableIds = (quotas, filterVariableIds) => {
  if (!quotas.length) return filterVariableIds

  const ignoreList = ['censusQuota', 'cintAge', 'cintGender']
  const quotaVariableIds = flatMap(quotas, (quota) => {
    if (ignoreList.includes(quota.id)) return []

    return quota.options.map(option => option.id)
  })

  return difference(filterVariableIds, quotaVariableIds)
}

const formatCintDefaultTargetGroup = (quotas, age_min, age_max, gender, variable_ids) => {
  // configure cint filters (excludes age & gender)
  const variableIds = skipOverlappingCintVariableIds(quotas, variable_ids)
  const targetGroup = {
    variableIds
  }

  // configure age filter
  const censusQuota = quotas.find(quota => quota.id === 'censusQuota')
  const isCensusQuotaConfigured = censusQuota && censusQuota.isValid
  const ageQuota = quotas.find(quota => quota.id === 'cintAge')
  const isAgeQuotaConfigured = ageQuota && ageQuota.isValid
  if (age_min && age_max && !isAgeQuotaConfigured && !isCensusQuotaConfigured) {
    targetGroup.minAge = age_min
    targetGroup.maxAge = age_max
  }

  // configure gender filter
  const genderQuota = quotas.find(quota => quota.id === 'cintGender')
  const isGenderQuotaConfigured = genderQuota && genderQuota.isValid
  if (gender.length === 1 && !isGenderQuotaConfigured && !isCensusQuotaConfigured) {
    targetGroup.gender = GENDER[gender[0]].id
  }

  return targetGroup
}

const formatCintTargetGroup = (quotaId, option) => {
  const targetGroup = {}
  if (quotaId === 'censusQuota') {
    targetGroup['minAge'] = option.from
    targetGroup['maxAge'] = option.to
    targetGroup['gender'] = GENDER[option.gender].id
  } else if (quotaId === 'cintAge') {
    targetGroup['minAge'] = option.from
    targetGroup['maxAge'] = option.to
  } else if (quotaId === 'cintGender') {
    targetGroup['gender'] = GENDER[option.id].id
  } else {
    targetGroup['variableIds'] = [option.id]
  }

  return targetGroup
}

const formatCintInterlockedTargetGroup = (quotaId, quotaOption, computedOption) => {
  const targetGroup = formatCintTargetGroup(quotaId, quotaOption)
  const interlockedTargetGroup = {...computedOption.targetGroup, ...targetGroup}
  const variableIds = []
  if (targetGroup.variableIds?.length) {
    variableIds.push(...targetGroup.variableIds)
  }
  if (computedOption.targetGroup.variableIds?.length) {
    variableIds.push(...computedOption.targetGroup.variableIds)
  }
  if (variableIds.length) {
    interlockedTargetGroup['variableIds'] = variableIds
  }

  return interlockedTargetGroup
}

const getOptionWithMaxErrorValue = (options) => {
  let maxErrorValue = 0
  let name = ''
  options.forEach((option) => {
    if (option.errorValue < maxErrorValue) return

    maxErrorValue = option.errorValue
    name = option.name
  })

  return name
}

const getOptionWithMinErrorValue = (options) => {
  let minErrorValue = 0
  let name = ''
  options.forEach((option) => {
    if (minErrorValue < option.errorValue) return

    minErrorValue = option.errorValue
    name = option.name
  })

  return name
}

const generateQuotaGroups = (params, quotas, interlockedQuotaIds) => {
  const {age_min, age_max, gender = [], number_of_submissions, variable_ids = []} = params
  const defaultTargetGroup = formatCintDefaultTargetGroup(quotas, age_min, age_max, gender, variable_ids)

  // configure applicable filters
  const defaultQuotaGroup = {
    name: 'General',
    limitType: 0,
    quotas: [
      {
        name: 'General',
        limit: number_of_submissions,
        targetGroup: defaultTargetGroup
      }
    ]
  }

  // when no quota is added, return default quota group (configured based on filters)
  if (!quotas.length) return [defaultQuotaGroup]

  // split interlocked and regular quotas
  const formattedQuotaGroups = []
  const regularQuotas = []
  const interlockedQuotas = []

  if (interlockedQuotaIds.length > 1) {
    quotas.forEach(quota => {
      if (interlockedQuotaIds.includes(String(quota.id))) {
        interlockedQuotas.push(quota)
      } else {
        regularQuotas.push(quota)
      }
    })
  } else {
    regularQuotas.push(...quotas)
  }

  // configure regular quotas
  regularQuotas.forEach(quota => {
    if (!quota.options) return

    const formattedQuota = []
    quota.options.forEach(option => {
      if (!option.participants) return

      formattedQuota.push({
        name: option.id,
        limit: option.participants,
        targetGroup: formatCintTargetGroup(quota.id, option)
      })
    })

    formattedQuotaGroups.push({
      name: quota.id,
      quotas: formattedQuota
    })
  })

  // when quotas are not interlocked, return quota group configured only with filters & regular quotas
  if (!interlockedQuotas.length) {
    // merge filters, if applicable
    const isDefaultQuotaGroupRequired = (defaultTargetGroup.minAge && defaultTargetGroup.maxAge)
      || defaultTargetGroup.gender
      || defaultTargetGroup.variableIds.length

    if (isDefaultQuotaGroupRequired) {
      formattedQuotaGroups.push(defaultQuotaGroup)
    }

    return formattedQuotaGroups
  }

  // configure interlocked quotas
  let computedInterlockedQuotas = []
  interlockedQuotas.forEach((quota, index) => {
    const quotaOptions = quota.options.filter(option => option.participants > 0)
    if (!quotaOptions.length) return

    if (index === 0) {
      computedInterlockedQuotas = quotaOptions.map(option => ({
        name: option.id,
        limit: option.participants,
        targetGroup: formatCintTargetGroup(quota.id, option)
      }))
      return
    }

    // compute interlocked quotas
    let totalParticipants = 0
    const computedQuotas = quotaOptions.flatMap(quotaOption => {
      return computedInterlockedQuotas.map(computedOption => {
        const actualParticipants = computedOption.limit * quotaOption.percent * 0.01
        const roundedParticipants = Math.round(actualParticipants)
        const actualPercentage = (actualParticipants / number_of_submissions) * 100
        const roundedPercentage = (roundedParticipants / number_of_submissions) * 100
        totalParticipants += roundedParticipants

        return {
          name: `${computedOption.name}_${quotaOption.id}`,
          limit: roundedParticipants,
          errorValue: roundedPercentage - actualPercentage,
          targetGroup: formatCintInterlockedTargetGroup(quota.id, quotaOption, computedOption)
        }
      })
    })

    // rounding algorithm
    let extraParticipants = totalParticipants - number_of_submissions

    if (extraParticipants > 0) {
      for (let i = 0; i < extraParticipants; i++) {
        const name = getOptionWithMaxErrorValue(computedQuotas)
        const idx = computedQuotas.findIndex(q => q.name === name)
        computedQuotas[idx].limit = computedQuotas[idx].limit - 1
        computedQuotas[idx].errorValue = 0
      }
    } else if (extraParticipants < 0) {
      for (let i = 0; i < Math.abs(extraParticipants); i++) {
        const name = getOptionWithMinErrorValue(computedQuotas)
        const idx = computedQuotas.findIndex(q => q.name === name)
        computedQuotas[idx].limit = computedQuotas[idx].limit + 1
        computedQuotas[idx].errorValue = 0
      }
    }

    // format interlocked quotas for API
    computedInterlockedQuotas = computedQuotas
      .filter(computedQuota => computedQuota.limit > 0)
      .map(computedQuota => {
        const {name, limit, targetGroup} = computedQuota
        return {name, limit, targetGroup}
      })
  })

  const interlockedQuotaGroup = {
    name: 'interlockedQuota',
    quotas: computedInterlockedQuotas
  }

  formattedQuotaGroups.push(interlockedQuotaGroup)
  return formattedQuotaGroups
}

const validateAgeRange = (from, to, quota) => {
  const parsedFrom = parseInt(from)
  const parsedTo = parseInt(to)

  if (isNil(parsedFrom) || isNil(parsedTo))
    return false

  if (parsedFrom < quota.min_value || parsedFrom > quota.max_value)
    return false

  if (parsedTo < quota.min_value || parsedTo > quota.max_value)
    return false

  return parsedFrom <= parsedTo
}

const getFormattedCintAttributes = (filters) => {
  const attributes = {}

  filters && filters.forEach(filter => {
      if (filter.id === ageFilterForCint.id) {
        attributes.age_min = filter.from
        attributes.age_max = filter.to
      }

      if (filter.id === genderFilterForCint.id) {
        attributes.gender = filter.filter_options
          .filter(filter_option => filter_option.is_checked)
          .map(filter_option => filter_option.title)
      }
    }
  )

  return attributes
}

const getFormattedVariableIds = (filters) => {
  if (!filters) return []

  const variableIds = []

  filters.forEach(filter => {
    if (filter.id === ageFilterForCint.id || filter.id === genderFilterForCint.id) return

    const selectedVariables = filter.variables
      .filter(variable => variable.is_checked)
      .map(variable => variable.id)

    variableIds.push(...selectedVariables)
  })

  return variableIds
}

const handleScrollToQuota = (e) => {
  e.preventDefault()
  const quotaId = e.target.getAttribute('data-quota-id')
  highlightControl(quotaId)
  setTimeout(() => {
    removeHighlight(quotaId)
  }, 1500)
}

const getFormattedCintQuotas = (quotas) => {
  const formattedQuotas = quotas.map(quota => {
    const formattedQuota = cloneDeep(quota)
    delete formattedQuota.isValid
    return formattedQuota
  })

  return JSON.stringify(formattedQuotas)
}

const formatCensusData = (censusData, options) => {
  const numberOfSubmissions = options['numberOfSubmissions']
  let totalParticipants = 0
  const formattedCensusData = censusData['census_data'].map(quotaOption => {
    const actualParticipants = quotaOption.percentage * 0.01 * numberOfSubmissions
    const roundedParticipants = Math.round(actualParticipants)
    const actualPercentage = (actualParticipants / numberOfSubmissions) * 100
    const roundedPercentage = (roundedParticipants / numberOfSubmissions) * 100
    totalParticipants += roundedParticipants

    let id

    if (options['isGenderOnly']) {
      id = quotaOption.gender
    } else {
      id = `${quotaOption.min_age}-${quotaOption.max_age}`
      if (quotaOption.gender) {
        id += `-${quotaOption.gender}`
      }
    }

    return {
      id,
      name: id,
      participants: roundedParticipants,
      errorValue: roundedPercentage - actualPercentage,
    }
  })

  // rounding algorithm
  let extraParticipants = totalParticipants - numberOfSubmissions

  if (extraParticipants > 0) {
    for (let i = 0; i < extraParticipants; i++) {
      const name = getOptionWithMaxErrorValue(formattedCensusData)
      const idx = formattedCensusData.findIndex(q => q.name === name)
      formattedCensusData[idx].participants = formattedCensusData[idx].participants - 1
      formattedCensusData[idx].errorValue = 0
    }
  } else if (extraParticipants < 0) {
    for (let i = 0; i < Math.abs(extraParticipants); i++) {
      const name = getOptionWithMinErrorValue(formattedCensusData)
      const idx = formattedCensusData.findIndex(q => q.name === name)
      formattedCensusData[idx].participants = formattedCensusData[idx].participants + 1
      formattedCensusData[idx].errorValue = 0
    }
  }

  return {
    ...censusData,
    census_data: formattedCensusData
  }
}

export {
  reactSelectStyles,
  createSelectOption,
  isValidObj,
  registerParticipantsInitialValues,
  unregisterParticipantsInitialValues,
  formatDemographicToCustomFilter,
  formatCustomAttributesFilter,
  getPreviousParticipationText,
  getFormattedPrescreenID,
  parseFilterValue,
  isGuidedTrackLink,
  getHelpText,
  formatCintProfilingFilter,
  getActivityLanguageById,
  getActivityLanguageByName,
  commonQSAttributes,
  formatAsCurrency,
  screeninLink,
  screenoutLink,
  removeHighlight,
  highlightControl,
  urlRegex,
  getFormattedActivityUrl,
  ageFilterForCint,
  genderFilterForCint,
  censusQuota,
  genderQuota,
  generateQuotaGroups,
  validateAgeRange,
  getFormattedCintAttributes,
  getFormattedVariableIds,
  handleScrollToQuota,
  getFormattedCintQuotas,
  formatCensusData
}
